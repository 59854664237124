/* screen breaks */

/** menu heights **/

.double-border,
#project-detail > div .date,
#article-detail > div .date {
	border-style: double;
	border-width: 3px;
	border-color: #E8E8E8;
}

.box-shadow {
	-webkit-box-shadow: 0px 10px 25px 5px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 10px 25px 5px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 10px 25px 5px rgba(0, 0, 0, 0.5);
}

.all-transitions {
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-ms-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
}

.round-button,
.social-links .social-button {
	border-radius: 50%;
	height: 2em;
	width: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
}

.social-links {
	display: flex;
}

.social-links .social-button {
	background-color: #555;
	font-size: 1em;
	height: 26px;
	width: 26px;
	padding: 0.25em;
	box-sizing: border-box;
	margin: 0.25em;
}

.social-links .social-button,
.social-links .social-button i,
.social-links .social-button em {
	color: white;
	display: flex;
	align-items: center;
	font-size: 0.9em;
}

.social-links .social-button a {
	color: white;
}

/* screen breaks */

/** menu heights **/

/** Variables and mixins for screen breaks **/

#project-detail > div,
#article-detail > div {
	padding: 1em 0;
	box-sizing: border-box;
}

#project-detail > div .date,
#article-detail > div .date {
	padding: 20px;
	padding-bottom: 15px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	margin-right: 40px;
}

#project-detail > div .date span,
#article-detail > div .date span {
	display: block;
}

#project-detail > div#article-detail-left.one-half,
#article-detail > div#article-detail-left.one-half {
	display: flex;
	flex-flow: row;
	flex: 1 0 auto;
}

#project-detail > div#article-detail-left.one-half #image-wrapper,
#article-detail > div#article-detail-left.one-half #image-wrapper {
	flex: 8;
	max-width: 100%;
	overflow: hidden;
}

#project-detail > div h2,
#article-detail > div h2 {
	margin-bottom: 10px;
}

#article-detail.date-only {
	display: flex;
	flex-direction: row;
}

#article-detail.date-only #information-wrapper {
	flex: 1 0 auto;
}

#project-detail #image-wrapper,
#article-detail #image-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding-right: 20px;
	max-width: 100%;
}

#project-detail #image-wrapper .detail-image img,
#article-detail #image-wrapper .detail-image img {
	max-width: 100%;
	height: auto;
}

#project-detail #image-wrapper ul,
#article-detail #image-wrapper ul {
	display: flex;
	flex-flow: column;
	align-items: flex-end;
	padding: 0;
	margin: 0;
	width: 100%;
	height: auto;
}

#project-detail #image-wrapper ul li.sigProThumb,
#article-detail #image-wrapper ul li.sigProThumb {
	margin: 0.5em 0;
	flex: 1 0 auto;
	width: 100%;
	height: auto;
}

#project-detail #image-wrapper ul li.sigProThumb span,
#article-detail #image-wrapper ul li.sigProThumb span {
	width: 100%;
	height: auto;
	padding: 0;
	margin: 0;
	background: none;
	float: none;
	border: none;
}

#project-detail #image-wrapper ul li.sigProThumb span a[style],
#article-detail #image-wrapper ul li.sigProThumb span a[style] {
	height: auto !important;
	display: flex;
}

#project-detail #image-wrapper ul li.sigProThumb span a[style] img,
#article-detail #image-wrapper ul li.sigProThumb span a[style] img {
	display: flex;
	background-position: top;
}

#project-detail #image-wrapper .detail-image:first-child,
#article-detail #image-wrapper .detail-image:first-child {
	margin-top: 0;
	max-width: 100%;
}

#project-detail #information-wrapper,
#article-detail #information-wrapper {
	max-width: 100%;
}

#project-detail #information-wrapper section,
#project-detail #information-wrapper #navigation,
#project-detail #information-wrapper #information-wrapper,
#article-detail #information-wrapper section,
#article-detail #information-wrapper #navigation,
#article-detail #information-wrapper #information-wrapper {
	margin: 0 0 4em 0;
}

#project-detail #information-wrapper #breadcrumb-wrapper ul li:nth-child(2,
2),
#project-detail #information-wrapper #breadcrumb-wrapper ul li:nth-child(3,
3),
#article-detail #information-wrapper #breadcrumb-wrapper ul li:nth-child(2,
2),
#article-detail #information-wrapper #breadcrumb-wrapper ul li:nth-child(3,
3) {
	display: none;
}

#project-detail #information-wrapper #project-navigation,
#article-detail #information-wrapper #project-navigation {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
}

#project-detail #information-wrapper #project-navigation a,
#article-detail #information-wrapper #project-navigation a {
	width: 3em;
	height: 3em;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #E8E8E8;
}

#project-detail #information-wrapper #project-navigation a.inactive,
#article-detail #information-wrapper #project-navigation a.inactive {
	cursor: default;
}

#project-detail #information-wrapper #project-navigation a.inactive *,
#article-detail #information-wrapper #project-navigation a.inactive * {
	color: #E8E8E8;
}

#project-detail #information-wrapper #project-navigation a *,
#article-detail #information-wrapper #project-navigation a * {
	font-size: 2em;
	font-weight: 200;
}

#project-detail #information-wrapper #project-description,
#article-detail #information-wrapper #project-description {
	margin-top: 4em;
}

#project-detail #information-wrapper #project-description ul,
#article-detail #information-wrapper #project-description ul {
	display: block;
	list-style: none;
	width: 50%;
	margin: 0 auto;
	padding: 0;
}

#project-detail #information-wrapper #project-description ul li,
#article-detail #information-wrapper #project-description ul li {
	text-transform: uppercase;
	display: block;
	padding: 1em;
	border-bottom: 1px solid #E8E8E8;
	text-align: center;
}

#project-detail #information-wrapper #project-description ul li:last-child,
#article-detail #information-wrapper #project-description ul li:last-child {
	border-bottom: none;
}

#project-detail #information-wrapper {
	padding-left: 30px;
}

@media only screen and (min-width: 819px) {

#project-detail > div:first-child,
#article-detail > div:first-child {
	padding-left: 0;
	padding-top: 0;
}

#project-detail > div:last-child,
#article-detail > div:last-child {
	padding-right: 0;
}

#project-detail #image-wrapper {
	border-right: 1px solid #E8E8E8;
}

}

@media only screen and (max-width: 1023px) {

#project-detail > div,
#article-detail > div {
	padding: 20px 10px;
}

#project-detail > div#image-wrapper,
#article-detail > div#image-wrapper {
	padding-right: 15px;
}

#project-detail > div#information-wrapper,
#article-detail > div#information-wrapper {
	padding-left: 15px;
}

}

@media only screen and (max-width: 819px) {

#project-detail {
	flex-direction: column-reverse;
}

#project-detail #image-wrapper {
	justify-content: center;
}

}

@media only screen and (max-width: 639px) {

#project-detail > div .date,
#article-detail > div .date {
	margin-right: 20px;
}

#project-detail > div#article-detail-left.one-half #image-wrapper,
#article-detail > div#article-detail-left.one-half #image-wrapper {
	flex: 7;
	max-width: 100%;
}

#project-detail > div #image-wrapper,
#article-detail > div #image-wrapper {
	margin-right: 0;
	padding-right: 0;
}

#project-detail {
	flex-direction: column-reverse;
}

#project-detail #information-wrapper #project-navigation a,
#article-detail #information-wrapper #project-navigation a {
	width: 2em;
	height: 2em;
}

#project-detail #information-wrapper #project-navigation a *,
#article-detail #information-wrapper #project-navigation a * {
	font-size: 1.75em;
}

#project-detail #information-wrapper #project-navigation h1,
#article-detail #information-wrapper #project-navigation h1 {
	padding-top: 5px;
}

#project-detail #information-wrapper #project-description,
#article-detail #information-wrapper #project-description {
	margin-top: 2em;
}

}

